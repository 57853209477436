import { getConterPartyName, getConterPartyLogo } from '@helpers/constants';
import { getOpportunityBySlug } from '@services/opportunities.service';
import Referral from '@components/Referral';
import NextHeadTag from '@ui/molecules/NextHeadTag';
import { HeadTagData } from '@helpers/headTagData';
import {useEffect} from "react";
import {logEvent, moengageEventConstants} from "@services/moengage";

const login = ({ opportunityDetails }) => {
    useEffect(() => {
        logEvent({eventName: moengageEventConstants.VIEWED_LOGIN_PAGE,});
    }, []);
    return (
        <>
            <NextHeadTag {...HeadTagData?.['/login']} />
            <Referral
                showEmail={false}
                opportunityDetails={opportunityDetails}
                login={true}
            />
        </>
    );
};

export const getServerSideProps = async ({ req, query }) => {
    let opportunityDetails = null;

    /**
     * Commenting this code as it is not in use anymore, No need to make any changes related to this.
     */
    // const slug = query?.opportunity;
    // if (slug) {
    //     const response = await getOpportunityBySlug(slug);
    //     if (response.status) {
    //         const opportunity = response.entity;

    //         if (opportunity) {
    //             opportunityDetails = {
    //                 opportunityId: opportunity.id,
    //                 opportunityTitle: opportunity.title,
    //                 opportunityName: getConterPartyName(opportunity),
    //                 opportunityLogo: getConterPartyLogo(opportunity),
    //             };
    //         }
    //     }
    // }

    return {
        props: { opportunityDetails },
    };
};

export default login;
